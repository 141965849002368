import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Layout from '../components/Layout'

const Trip = styled.article`
  display: grid;
  grid-template-columns: minmax(0, 320px) 1fr;
  grid-gap: 1em;
  margin-bottom: 1em;
`

export default function TripsPage({
  data: {
    allMarkdownRemark: { edges },
    site: {
      siteMetadata: { title },
    },
  },
}) {
  const trips = edges.map(({ node }) => node)
  return (
    <Layout>
      <Helmet>
        <title>Trips | {title}</title>
      </Helmet>
      <section className="hero">
        <h1 className="title">Trips</h1>
      </section>
      <section>
        {trips.map(({ id, excerpt, frontmatter, coverImage }) => (
          <Link to={frontmatter.slug} key={id}>
            <Trip>
              {coverImage ? (
                <Img fluid={coverImage.childImageSharp.fluid} />
              ) : (
                <div />
              )}
              <div>
                <h3>{frontmatter.title}</h3>
                <p>{excerpt}</p>
                <p>
                  Adventurers:
                  <br />
                  {frontmatter.adventurers.map((name, i, arr) => (
                    <span key={name}>
                      {name}
                      {arr.length - 1 !== i && ', '}
                    </span>
                  ))}
                </p>
                <p>
                  {frontmatter.startDate} - {frontmatter.endDate}
                </p>
              </div>
            </Trip>
          </Link>
        ))}
      </section>
    </Layout>
  )
}

TripsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const TripsQuery = graphql`
  query TripsPage {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { tags: { eq: "trip" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          coverImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          frontmatter {
            slug
            title
            templateKey
            startDate(formatString: "DD/M/YY")
            endDate(formatString: "DD/M/YY")
            adventurers
          }
        }
      }
    }
  }
`
